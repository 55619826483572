<template>
    <div>
        <h2 class="py-2 px-10 requirements d-inline">
            {{ title }}
        </h2>
        <v-card outlined max-width="400" class="mt-4">
            <v-list dense color="appBackground">
                <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-icon class="mx-1 align-self-center">
                        <v-icon :color="`${color} darken-2`" size="10">
                            fa-solid fa-circle-dot
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            class="requirements-list-item"
                            v-text="item"
                        ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "Requirements",

    props: {
        items: {
            required: true,
            type: Array,
        },
        title: {
            required: true,
            type: String,
        },
        color: {
            required: false,
            type: String,
            default: "primary",
        },
    },
};
</script>

<style lang="scss" scoped>
.requirements {
    background-color: var(--v-primary-lighten1);
}

.requirements-list-item {
    font-size: 1em !important;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--v-text-lighten1);
    white-space: normal;
}
</style>
