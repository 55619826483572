<template>
    <div>
        <v-stepper v-model="stepper">
            <v-stepper-header>
                <template v-for="n in steps">
                    <v-stepper-step
                        :key="`${n}-step`"
                        :complete="stepper > n"
                        :step="n"
                        editable
                    >
                        Step {{ n }}
                    </v-stepper-step>

                    <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content
                    v-for="(step, i) in projectSteps"
                    :key="`${i + 1}-content`"
                    :step="i + 1"
                >
                    <v-img
                        :src="step.image_url"
                        class="white--text align-end"
                        contain
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        :height="300"
                    >
                        <v-card-title
                            class="card-title"
                            v-text="step.title"
                        ></v-card-title>
                    </v-img>
                    <v-alert
                        text
                        color="accent darken-1"
                        icon="fa-solid fa-check"
                        border="left"
                        class="mt-6"
                    >
                        {{ step.description }}
                    </v-alert>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-chip-group
            v-model="stepper"
            class="mt-2"
            mandatory
            active-class="accent--text"
        >
            <v-chip
                v-for="step in steps"
                :key="step"
                :value="step"
                class="ma-2"
                color="primary lighten1"
                text-color="white"
                @click="changeStep(step)"
            >
                <v-avatar left>
                    <v-icon size="20" class="mx-4">
                        fa-solid fa-shoe-prints
                    </v-icon>
                </v-avatar>
                {{ $t(`builder.components.step`) }} {{ step }}
            </v-chip>
        </v-chip-group>
    </div>
</template>

<script>
export default {
    name: "ViewStepper",

    props: {
        projectSteps: {
            required: true,
            type: Array,
        },
    },

    data() {
        return {
            stepper: 1,
            steps: 1,
        };
    },

    methods: {
        changeStep(step) {
            this.stepper = step;
        },
    },

    created() {
        this.steps = this.projectSteps.length;
    },

};
</script>
